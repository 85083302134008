import React from 'react';

export default function IndexSectionCareers6() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-12 md:py-24 bg-body overflow-hidden">
  <div className="relative container mx-auto px-4">
    <div className="absolute top-0 left-0 ml-52 -mt-40 w-160 h-160 bg-gradient-to-t from-purple-600 to-darkBlue-900 rounded-full filter blur-4xl" />
    <div className="absolute top-0 left-0 ml-148 -mt-40 w-135 h-135 bg-gradient-to-t from-blue-500 to-darkBlue-900 rounded-full filter blur-4xl" />
    <img className="hidden md:block absolute top-0 left-0 -ml-6 lg:-ml-16 xl:-ml-0 w-32 lg:w-52 xl:w-auto lg:mt-40" src="casper-assets/careers/robot-stand.png" alt />
    <img className="hidden md:block absolute top-0 right-0 -mr-6 lg:-mr-16 xl:-mr-0 w-32 lg:w-52 xl:w-auto lg:mt-96" src="casper-assets/careers/robot-wrench.png" alt />
    <div className="relative">
      <div className="max-w-xl mx-auto mb-16 text-center">
        <h2 className="font-heading text-4xl xs:text-5xl sm:text-6xl font-medium text-white tracking-tight mb-2">Ao fazer parte desses cursos,&nbsp;</h2>
        <p className="text-xl text-gray-500">Nunc et tellus non erat accumsan aliquam eget non mi. Integer fringilla pellentesque finibus.</p>
      </div>
      <div className="max-w-2xl mx-auto">
        <a className="block pb-10 mb-10 border-b border-white border-opacity-20" href="#">
          <div className="xs:flex items-start xs:-mx-4">
            <div className="xs:px-4 mb-4 xs:mb-0">
              <h3 className="text-2xl text-white font-medium">Product Designer</h3>
              <p className="text-sm text-gray-500 mb-2">Excepteur sint occaecat cupidatat non proident.</p>
              <div className="inline-flex mr-8 items-center">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z" stroke="#994DE6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="#994DE6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="ml-2 text-sm font-medium text-white">Ponorogo</span>
              </div>
              <div className="inline-flex items-center">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#4DCFEC" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 6V12L16 14" stroke="#4DCFEC" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="ml-2 text-sm font-medium text-white">Full-time</span>
              </div>
            </div>
            <div className="ml-auto xs:px-4">
              <span className="inline-block py-px px-1 text-xs font-medium text-black bg-sky-900 rounded">Design</span>
            </div>
          </div>
        </a>
        <a className="block pb-10 mb-10 border-b border-white border-opacity-20" href="#">
          <div className="xs:flex items-start xs:-mx-4">
            <div className="xs:px-4 mb-4 xs:mb-0">
              <h3 className="text-2xl text-white font-medium">Sr. Mobile Developer</h3>
              <p className="text-sm text-gray-500 mb-2">Excepteur sint occaecat cupidatat non proident.</p>
              <div className="inline-flex mr-8 items-center">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z" stroke="#994DE6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="#994DE6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="ml-2 text-sm font-medium text-white">New York</span>
              </div>
              <div className="inline-flex items-center">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#4DCFEC" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 6V12L16 14" stroke="#4DCFEC" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="ml-2 text-sm font-medium text-white">Full-time</span>
              </div>
            </div>
            <div className="ml-auto xs:px-4">
              <span className="inline-block py-px px-1 text-xs font-medium text-black bg-yellow-500 rounded">Developer</span>
            </div>
          </div>
        </a>
        <a className="block pb-10 mb-10 border-b border-white border-opacity-20" href="#">
          <div className="xs:flex items-start xs:-mx-4">
            <div className="xs:px-4 mb-4 xs:mb-0">
              <h3 className="text-2xl text-white font-medium">3D Model</h3>
              <p className="text-sm text-gray-500 mb-2">Excepteur sint occaecat cupidatat non proident.</p>
              <div className="inline-flex mr-8 items-center">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z" stroke="#994DE6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="#994DE6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="ml-2 text-sm font-medium text-white">Ponorogo</span>
              </div>
              <div className="inline-flex items-center">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#4DCFEC" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 6V12L16 14" stroke="#4DCFEC" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="ml-2 text-sm font-medium text-white">Full-time</span>
              </div>
            </div>
            <div className="ml-auto xs:px-4">
              <span className="inline-block py-px px-1 text-xs font-medium text-black bg-sky-900 rounded">Graphic</span>
            </div>
          </div>
        </a>
        <a className="block" href="#">
          <div className="xs:flex items-start xs:-mx-4">
            <div className="xs:px-4 mb-4 xs:mb-0">
              <h3 className="text-2xl text-white font-medium">Marketing Manager</h3>
              <p className="text-sm text-gray-500 mb-2">Excepteur sint occaecat cupidatat non proident.</p>
              <div className="inline-flex mr-8 items-center">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z" stroke="#994DE6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="#994DE6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="ml-2 text-sm font-medium text-white">Tokyo</span>
              </div>
              <div className="inline-flex items-center">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#4DCFEC" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 6V12L16 14" stroke="#4DCFEC" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="ml-2 text-sm font-medium text-white">Full-time</span>
              </div>
            </div>
            <div className="ml-auto xs:px-4">
              <span className="inline-block py-px px-1 text-xs font-medium text-black bg-red-500 rounded">Marketing</span>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

