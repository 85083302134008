import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionHeaders1 from '../components/headers/IndexSectionHeaders1';
import IndexSectionHeaders4 from '../components/headers/IndexSectionHeaders4';
import IndexSectionLogoClouds5 from '../components/logo-clouds/IndexSectionLogoClouds5';
import IndexSectionHowItWorks2 from '../components/how-it-works/IndexSectionHowItWorks2';
import IndexSectionCareers6 from '../components/careers/IndexSectionCareers6';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionHeaders1 />
      <IndexSectionHeaders4 />
      <IndexSectionLogoClouds5 />
      <IndexSectionHowItWorks2 />
      <IndexSectionCareers6 />
    </React.Fragment>
  );
}

